import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/ARW48BBBG2M">
    <SEO title="How to Encourage People? - Relationships" />
  </Layout>
)
export default SermonPost
